<template>
  <div class="container">
    <div class="row text-center my-5" v-if="busy">
      <div class="col">
        <i class="fad fa-spinner fa-5x fa-spin text-primary"></i>
      </div>
    </div>
    <div v-else>
      <div class="row mb-3">
        <div class="col mx-auto">
          <div class="card border-0 shadow-sm">
            <div class="card-body">
              <div class="row mb-3">
                <div class="col">
                  <h5>{{this.plan.title}}</h5>
                </div>
              </div>
              <div class="row mb-3" v-if="plan.requests.length > 0">
                <div class="col">
                  <button v-for="request in plan.requests"
                          @click="viewRequest(request)"
                          class="btn btn-lg mb-2 text-left w-100 position-relative"
                          :class="request.status === 'sent' ? 'btn-outline-primary' : 'btn-light'">
                    <span class="badge badge-danger badge-top-right" v-if="request.status === 'sent'">New</span>
                    <small>{{ request.created_at | formatDate }}</small>
                    <span class="text-capitalize badge ml-2 badge-primary"
                          v-if="request.status !== 'sent'">
                        {{ request.status | formatStatus}}
                      </span>
                  </button>
                </div>
              </div>
              <div class="row" v-else>
                <div class="col mx-auto text-center">
                  <p>No requests found.</p>
                  <p>Requests to complete this Health Tracker plan will show here.</p>
                  <p>Next Request: {{this.plan.next_request_date | formatDate}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: [],
  data() {
    return {
      busy: true,
      plan: null,
    };
  },
  computed: {
    user() {
      return this.$store.user;
    }
  },
  methods: {
    fetchPlan() {
      this.$axios
              .get(process.env.VUE_APP_API_URL + "/client/mymop/" + this.$route.params.id)
              .then(({ data }) => {
                this.plan = data;
                if (this.plan.requests.length === 1){
                  this.viewRequest(this.plan.requests[0]);
                } else {
                  this.busy = false;
                }
              });
    },
    viewRequest(request) {
      this.$router.push("/tracker/request/" + request.id);
    }
  },
  mounted() {
    this.fetchPlan();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
    formatStatus(status) {
      if (status === "sent"){
        return "Awaiting Response";
      } else {
        return status;
      }
    }
  },
};
</script>

<style>
</style>
